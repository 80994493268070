import React from "react";

const IntroText = () => (
  <>
    <h1>Kontakt</h1>
    <p>
      Du hast Fragen oder Anregungen zu exSPIRIence? Dann hinterlasse bequem per
      Kontaktformular eine Nachricht. Gerne antworten wir dir schnellstmöglich
      per E-Mail.
    </p>
  </>
);

export default IntroText;
